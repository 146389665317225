<template>
<div class="flea">

    <div class="market-list-item" @click="gomarket(item.id)"  v-for="(item,index) in fleaData" :key="index">
        <img class="market-img"  :src="item.picture[0]"/>
        <div class="market-title white-space-2">
            <div class="market-title-mail" v-if="item.is_free_shipping">包邮</div>
            {{item.title}}
        </div>
        <div class="market-integral">
            {{item.credit}}积分
            <span class="want">
                {{$handle.tranNumber( item.collect_num,3)}}个想要
            </span>
        </div>
        <div class="vertical-center">
            <user-info :wechat="item.user_profile && item.user_profile.wechat" :labelimg="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" :vipimg="item.user_profile && item.user_profile.user && item.user_profile.user.level"></user-info>
        <img v-if="type == 'sc'" src="~assets/images/index/star-a.png"   @click.stop="collect(item.id)"  class="btn-img"/>
        <img v-if="type == 'll'" src="~assets/images/index/del.png"   @click.stop="del(item.browse_id)"   class="btn-img"/>
        </div>
        
    </div>

</div>

</template>

<script>
import UserInfo from "components/userInfo";
export default {
  name: "flea",
  components: {UserInfo},
  props: ['fleaData','type'],
  data() {
    return {
      fleaDatalist: []
    }
  },
  created() {
    if (this.fleaData.length) {
      this.fleaDatalist = this.fleaData
    }
   
  },
  methods: {
        //删除
   del(id){
    let url = this.$api.communityBrowse + '/' +id
    this.$http.del(url,true).then(res => {
      if(res.data.success){
       this.$toast('删除成功');
       this.$emit('updata', );
      }
    })
  },
   //收藏
   collect(id){
    let url = this.$api.communityCollect
    let data = {
      model_id:id,
      model_type:'flea'
    }
    this.$http.post(url, data, true).then(res => {
      if(res.data.success){
     this.$emit('updata', );
      }
    })
  },
//跳转跳蚤详情
gomarket(id){
      this.$store.commit('M_ACTS','1')
     this.$router.push({path: '/community/market-show',query: {id: id}})
  },
  },
  watch: {
    fleaData(val, oldval) {
      this.fleaDatalist = val
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;

.flea {
  display: flex;
  flex-wrap: wrap;

  .market-list-item {
    width: 332/$r!important;
    box-sizing: border-box;
    margin-bottom: 30/$r;
    position: relative;

    &:nth-child(2n) {
      margin-left: 20/$r;
    }

    .market-img {
      width: 332/$r;
      height: 332/$r;
      border-radius: 6px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
      object-fit: cover;
    }

    .market-title {
      font-size: 26/$r;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      height: 70/$r;
      .market-title-mail {
        display: inline-block;
        width: 56/$r;
        height: 28/$r;
        background: #57AEFF;
        border-radius: 4/$r;
        font-size: 24/$r;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28/$r;
        text-align: center;
      }
    }

    .market-integral {
      font-size: 26/$r;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ED3D4B;
      margin: 7/$r 0 10/$r;

      .want {
        font-size: 22/$r;
        font-family: PingFang SC;
        font-weight: bold;
        color: #B3B3B3;
      }
    }

    .user-info-text {
      font-size: 22/$r;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}

.btn-img {
  position: absolute;
  right: 10/$r;
  width: 28/$r;
  height: 28/$r;
}

</style>
<style lang="scss">
$r: 750/16/1rem;
.flea{
  .userinfo .userinfo-name{
    font-size: 22/$r!important;
    color: #999999!important;
  }   
}
.userinfo-name{
  font-size: 22/$r!important;
  color: #999999!important;
  max-width: 130/$r;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
}
</style>