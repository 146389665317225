<template>
<div class="collection">
    <van-tabs v-model:active="active"  @click="onChange" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">

        <van-tab title="跳蚤市场"  name="1">
           <NoData v-if="fleaList && fleaList.length == 0 " :bottomBg="true" class="fleanodata" ></NoData>
            <div class="market-list" v-else>
                <div class="market-list-top">
                 
                </div>
               
                <div class="market-list-items">
                           <van-list
        v-model:loading="draftloading"
        :finished="draftfinished"
        :finished-text="fleaList.length > 5 ? '没有更多了':''"
        @load="draftonLoad"
        >
                    <flea :fleaData="fleaList" type='sc' @updata="updata()"></flea>
                       </van-list>
                </div>


            </div>
        </van-tab>
      <van-tab title="悬赏问答" name="0">
        <div class="reward-list">
          <div class="reward-list-top">

          </div>
          <van-list
            v-model:loading="bountyloading"
            :finished="bountyfinished"
            :finished-text="communityList.length > 5 ? '没有更多了':''"
            @load="bountyonLoad"
          >
            <bounty :bountyData="communityList" type="sc" @updata="updata()"></bounty>
          </van-list>
        </div>

      </van-tab>
      <van-tab title="资源互助" name="2">
        <div class="reward-list">
          <div class="reward-list-top">

          </div>
          <van-list
            v-model:loading="eleloading"
            :finished="elefinished"
            :finished-text="eleList.length > 5 ? '没有更多了':''"
            @load="eleonLoad"
          >
            <resources :electronData="eleList" type="sc" @updata="updata()"></resources>
          </van-list>
        </div>

      </van-tab>
    </van-tabs>
</div>


</template>

<script>
import NoData from "components/NoData";
import UserInfo from "components/userInfo";
import bounty from "../../../components/bounty"
import flea from "../../../components/flea"
import resources from "../../../components/resources"
export default {
  name: "user.collection",
  data(){
    return{
     active: '1',
        communityList:[],
        page:1,
        load:true,
        fleaList:[],
        fleapage:1,
        fleaLoad:true,
        bountyloading:false,
        bountyfinished:true,
        draftloading:false,
        draftfinished:true,
        eleList:[],
        elepage:1,
        eleload:true,
        eleloading:false,
        elefinished:true,
    }
  },

    created() {
         if (this.$store.getters.act) {
        this.active = this.$store.getters.act
         this.$store.commit('M_ACT','1')
      }
     this.getfleaList()
     this.getcommunityList()
      this.geteleList()
  },
  methods:{
  //跳蚤市场列表
    getfleaList(){
        let url = this.$api.communityCollect + '?model_type=flea&pageSize=5&page=' + this.fleapage 
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.fleaList = this.fleaList.concat(res.data.data)
                this.fleapage = this.fleapage + 1
                    this.draftloading = false
                 this.draftfinished = false
            }else{
              this.fleaLoad = false
                 this.draftfinished = true
            }
          }
        })
      },
      //电子资源列表
      geteleList(){
      let url = this.$api.communityCollect + '?model_type=electron&pageSize=5&page=' + this.elepage
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.eleList = this.eleList.concat(res.data.data),
              this.elepage = this.elepage + 1
                this.eleloading = false;
                this.elefinished = false
          }else{
            this.eleload = false
              this.elefinished = true
          }
        }
      })
      },
    //悬赏帮助列表
    getcommunityList(){
      let url = this.$api.communityCollect + '?model_type=bounty&pageSize=5&page=' + this.page
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.communityList = this.communityList.concat(res.data.data),
              this.page = this.page + 1
            this.bountyloading = false;
            this.bountyfinished = false
          }else{
            this.load = false
            this.bountyfinished = true
          }
        }
      })
    },
    onChange(event) {
        this.active = event
        },
         bountyonLoad(){
        if(this.load){
          this.getcommunityList()
        }
      },
    eleonLoad(){
      if(this.eleload){
        this.geteleList()
      }
    },
      draftonLoad(){
          if(this.fleaLoad){
          this.getfleaList()
        }
      },
          updata(){
        this.communityList=[],
        this.page=1,
        this.load=true,
        this.fleaList=[],
        this.fleapage=1,
        this.fleaLoad=true,
          this.eleList=[],
          this.elepage=1,
          this.eleload=true,
        this.getfleaList()
      this.getcommunityList()
            this.geteleList()
      }
  },
  components:{
    UserInfo,
    bounty,
    flea,
    resources,
    NoData
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/collection/collection.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.collection .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
.collection .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
}
.collection .fleanodata{
  margin-top: 96/$r;
}
</style>